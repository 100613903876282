export default {
    name: 'invite',
    components: {
        contact: () => import('@/components/contact')
    },
    data() {
        return {
            arrowRight: false,
            arrowLeft: false,
            current: null,
            menu: false,
            hero: 1,
            loaded: false,
            pixels: 0,
            lang: null
        }
    },
    methods: {
        setLang: function(lang) {
            this.lang = lang;
            window.localStorage.setItem('lang', lang);
            this.$i18n.locale = lang
        },
        testimonialScroll: function(direction = false) {
            let width = this.$refs.testimonials.getBoundingClientRect().width + 35;
            if(direction) this.$refs.testimonials.scrollBy(width, 0)
            else this.$refs.testimonials.scrollBy(-width, 0)
        },
        display: function(evt) {
            if(evt.target) {
                this.arrowLeft = evt.target.scrollLeft > 0
                this.arrowRight = evt.target.scrollWidth > evt.target.scrollLeft + evt.target.offsetWidth
                return false
            }
            this.arrowLeft = evt.scrollLeft > 0;
            this.arrowRight = evt.scrollWidth > evt.scrollLeft + evt.offsetWidth
        },
        init: function () {
            let count = 0, interval = setInterval(() => {
                count ++
                if(count >= 100) return clearInterval(interval)
                if(!this.$refs.testimonials) return false
                if(this.$refs.testimonials) this.$refs.testimonials.onscroll = this.display;
                this.display(this.$refs.testimonials)
                clearInterval(interval)
                this.current = window.location.hash
            }, 100)
        },
        getMostVisible: function (elements) {
            let element = null,
                viewportHeight = window.innerHeight,
                max = 0;
            for (let el of elements) {
                let visiblePx = this.getVisibleHeight(el, viewportHeight);
                if (visiblePx > max) {
                    max = visiblePx;
                    element = el;
                }
            }
            return `#${element.id}`;
        },
        getVisibleHeight: function (element, viewportHeight) {
            let rect = element.getBoundingClientRect(),
                height = rect.bottom - rect.top,
                visible = {
                    top: rect.top >= 0 && rect.top < viewportHeight,
                    bottom: rect.bottom > 0 && rect.bottom < viewportHeight
                },
                visiblePx = 0;

            if (visible.top && visible.bottom) {
                // Whole element is visible
                visiblePx = height;
            } else if (visible.top) {
                visiblePx = viewportHeight - rect.top;
            } else if (visible.bottom) {
                visiblePx = rect.bottom;
            } else if (height > viewportHeight && rect.top < 0) {
                let absTop = Math.abs(rect.top);

                if (absTop < height) {
                    // Part of the element is visible
                    visiblePx = height - absTop;
                }
            }

            return visiblePx;
        },
        animateLogo: function() {
            let svg = document.getElementById('logo')
            let scrolled = Math.round(document.getElementById(this.current.replace('#','')).scrollHeight)
            if(!this.pixels) this.pixels = window.scrollY
            let scrolledFromCurrent = window.scrollY - this.pixels;
            let position = Math.round((scrolledFromCurrent / scrolled) * 100)
            svg.style.transform = `translateY(${-(position*1.5)}%)`
        },
        animateNumber: function(obj, start, end, duration) {
            if(obj.innerHTML !== '0') return false
            let startTimestamp = null;
            const step = (timestamp) => {
                if (!startTimestamp) startTimestamp = timestamp;
                const progress = Math.min((timestamp - startTimestamp) / duration, 1);
                obj.innerHTML = Math.floor(progress * (end - start) + start);
                if (progress < 1) {
                    window.requestAnimationFrame(step);
                }
            };
            window.requestAnimationFrame(step);
        },
        handleScroll: function() {
            let elements = document.getElementsByTagName('section');
            this.current = this.getMostVisible(elements);
            if(window.location.hash !== this.current)
                window.history.pushState(null, this.current, `/${this.current}`);
            if(this.current.includes('rest')) this.animateLogo();
            else this.pixels = 0
            if(this.current.includes('count')) {
                this.animateNumber(this.$refs.events, 0, 100, 1000)
                this.animateNumber(this.$refs.guests, 0, 3000, 2000)
                this.animateNumber(this.$refs.invitations, 0, 1500, 1500)
            }
        },
        slide: function () {
            this.hero = this.hero >= 4? 1 : this.hero + 1
        }
    },
    mounted() {
        this.init()
        window.onscroll = this.handleScroll
        setInterval(this.slide, 5000)
        setTimeout(() => this.loaded = true, 500)
        this.lang = window.localStorage.getItem('lang') || 'es_MX'
        this.setLang(this.lang)
    },
    beforeDestroy() {
        if(this.$refs.testimonials) this.$refs.testimonials.onscroll = null;
        window.onscroll = null
    }
}