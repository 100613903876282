import Vue from 'vue'
import VueI18n from 'vue-i18n'
import es_MX from './es_MX.json'
import en_US from './en_US.json'

Vue.use(VueI18n)


let messages = {
    es_MX,
    en_US
}

export default new VueI18n({
    locale: 'es_MX',
    fallbackLocale: 'en_US',
    messages
});