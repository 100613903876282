import Vue from 'vue'
import App from './app'
import i18n from '@/i18n'

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  i18n,
}).$mount('#app')
